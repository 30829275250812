<template>
  <div class="about">
    <div class="bread">
      <i class="el-icon-arrow-left" @click="goback()"></i>
      联系我们
    </div>
    <div class="head"></div>
    <div class="content">
      <div class="box">
        <h3>联系人：</h3>
        <p>于老师</p>
      </div>
      <div class="box">
        <h3>联系电话：</h3>
        <p>13801057400</p>
      </div>
    </div>
    <div class="bg3"></div>
  </div>
</template>
<script>
export default {
  data() {
    return {};
  },
  created() {},
  methods: {
    goback() {
      this.$router.go(-1);
    },
  },
};
</script>
<style scoped lang="scss">
.about {
  color: #fff;
  background: url("../../assets/image2/background.png") no-repeat;
  background-size: 100% 100%;
  height: 100vh;
  position: relative;
  .bread {
    height: 50px;
    width: 100%;
    font-size: 16px;
    font-weight: 600;
    line-height: 50px;
    text-align: center;
    position: relative;
    i {
      position: absolute;
      left: 10px;
      top: 17px;
      font-weight: 600;
    }
  }
  .head {
    height: 95px;
    background: url("../../assets/image2/head.png") no-repeat;
    background-size: 100% 100%;
  }
  .content {
    z-index: 999;
    padding: 0 20px 0 38px;
    box-sizing: border-box;
    font-size: 14px;
    line-height: 20px;
    .box {
      padding-top: 35px;
      p {
        padding-left: 13px;
        font-weight: 300;
      }
      .pdtop {
        padding-top: 20px;
      }
      h3 {
        padding-bottom: 15px;
        font-weight: 600;
      }
    }
  }
  .bg3 {
    position: absolute;
    background: url("../../assets/image2/BG-3.png") no-repeat;
    background-size: 100% 100%;
    bottom: 0;
    left: 0;
    z-index: 9;
    width: 100%;
    height: 120px;
  }
}
</style>
